import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import themeConfig from '@/configs/themes/vuetify';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        dark: true,
        options: {
            customProperties: true,
        },
        themes: {
            dark: themeConfig.dark
        },
    },
    breakpoint: {
        thresholds: {
            se: 370
        }
    },
});

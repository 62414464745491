export default {
    are_you_sure: "Are you sure?",
    yes: "Yes",
    no: "No",
    back: "Back",
    copied_clipboard: "Copied",
    filters: "Filters",
    section: "Section",
    reset: "Reset",
    subscribed: "Subscribed",
    following: "Following",
    discover: "Discover",
    age: "Age",
    sex: "Sex",
    male: "Male",
    female: "Female",
    transgender: "Transgender",
    save: "Save",
    add: "Add",
    tags: "Tags",
    done: "Done",
    minimum_payment: "Minimum Payment",
    receive_payment: "Days To Receive Payment",
    check_out_more: "Check Out More",
    days: "Days",
    hours: "Hours",
    minutes: "Minutes",
    use_default: "Use as default",

    unlock_verify_account: "To unlock, verify you account",
    messages_disabled: "Oops.. Messages are disabled",
    not_contents: "You don’t have content yet",
    direct_message_title: "Direct Message",
    direct_message_description: "Write Message",
    subscribe: "SUBSCRIBE",
    month: "Month",
    direct_message: "DIRECT MESSAGE",
    balance_insufficient:
        "Oops.. your balance is insufficient. You can top up simply by clicking on the button below",
    positive_balance_error_on_account_deletion:
        "You must withdraw your fund from the wallet before deleting your profile",
    has_subscribers_error_on_account_deletion:
        "Your account will be deleted when your last subscriber expires on date {renewal_at} make sure to withdraw all your funds or your account won’t be deleted",
    wallet: "wallet",
    report: "Report",
    report_placeholder: "Let us know why you want to report",
    send: "Send",
    subscriptions: "Subscriptions",
    subscription_include: "The Subscription Include:",
    active_until: "Active until",
    support_model: "Support the Noodzler",
    unlock_all_stories: "Unlock all the content",
    unlimited_direct_messages: "Unlimited Direct Messages",
    unlock_content: "Unlock content for",
    confirm_ending_stream: "Are you sure you want to end stream?",
    no_streams_scheduled: `{username} doesn't have any scheduled streams`,
    not_available_to_start_streaming:
        "The stream starts on {date}, that's in {difference}",
    streams_restricted: "You've been suspended",
    cancel_subscription: "Subscription cancellation",
    cancel_subscription_desc:
        "Your subscription will end at the next renewal date and you won't be charged.",
    your_must: "Your must",
    your_must_before_content:"To keep Noodzly a more safe place, you must",
    verify_account: "verify your account",
    unlock: "Unlock",
    update: "Update",
    open: "Open",
    reset_password: "Reset Password",
    password: "Password",
    new_password: "New Password",
    verify_password: "Verify Password",
    forget_password: "Forget Password",
    password_reset_confirmed: "Password Reset Confirmed",
    error_token_email: "The token and email is required.",
    email: "Email",
    write_something: "Whats'up?",
    my_story: "MY STORY",
    subscribers: "SUBSCRIBERS",
    chat: "CHAT",
    free: "Free",
    post: "POST",
    tags_hint: "Add max 3 tags",
    boost_tags_hint: "Add max 10 tags",
    redo: "Redo",
    set_image: "Set Image",
    purchased: "PURCHASED",
    gives_you: "gives you",
    tips: "TIPS",
    total: "total",
    delete_story_hint:
        "You will not lose your earnings but if someone already purchased it, it will be deleted after 24 hours from the purchase.",
    modify_content: "Modify your content",
    your_message: "Your Message",
    profile_link: "Profile Link",
    unblock: "Unblock",
    block: "Block",
    subscribe_for: "Subscribe For",
    top_up: "Top Up",
    top_up_via_crypto: "Top Up via Crypto",
    top_up_via_card: "Top Up via Card",
    top_up_warning: "* VAT and transaction fee may apply",
    active: "Active",
    expired: "Expired",
    cancel: "Cancel",
    renew: "Renew",
    balance: "Balance",
    withdraw: "Withdraw",
    my_subscriptions: "My Subscriptions",
    from: "From",
    to: "To",
    email_verify: "Verify Email",

    or: "or",
    delete: "delete",
    unfollow: "unfollow",
    something_is_missing: "Oops.. Something is missing",
    to_turn_off_price: "to turn off messages, set the price to 0 in the",
    noodzlers_cant_turn_off_messages: "Noodzlers can't turn off messages",
    set_messages_price_error:
        "Messages price mustn't be greater than subscription price - ",
    set_subscription_price_error:
        "Subscription price must be greater than message price - ",
    messages_page: "messages page",
    subscription_page: "subscription page",

    request: "Request",
    send_funds: "{username} cannot receive tips.",
    countdown_text: "10s countdown will start for",
    photo: "Photo",
    video: "Video",

    access_camera_title: "Camera and Mic",
    access_camera_description:
        "Noodzly wants to use your camera and microphone.",
    access_denied_camera_description:
        "You have blocked access to the camera and mic, the application will not work. Check your browser settings.",
    deny: "Deny",
    allow: "Allow",
    accept: "Accept",
    confirm_your_password: "To delete your account confirm your password",
    password_placeholder: "Type your password",
    confirm_and_delete: "Confirm and Delete",
    subscribe_for_free: "SUBSCRIBE FOR FREE",
    has_no_tips: "No offers yet",
    block_desktop: {
        only_mobile: "Please use the mobile version",
        title: "For a better experience we recommend to use the mobile version. Use the guide below:",
        iphone: {
            title: "iPhone",
            list: [
                "Open Safari on your iPhone and navigate to Noodzly.com",
                'Tap on the "Share" icon at the bottom. This looks like a square with an arrow pointing upwards.',
                'Select the "Add to Home Screen" icon.',
                "Enjoy!",
            ],
        },
        android: {
            title: "Android",
            list: [
                "Open Google Chrome and navigate to Noodzly.com",
                'Tap on the "Options" icon. It may look like three vertical dots at the top.',
                'Click "Add to Home Screen.',
                "Enjoy!",
            ],
        },
    },

    withdrawal_warning: {
        bank_transfer: {
            description: "Minimum amount to withdraw is 100$",
        },
        bitsafe: {
            description: "Minimum amount to withdraw is 50$",
        },
        wire_transfer: {
            description: "Minimum amount to withdraw is {min_amount}{currency}"
        },
        crypto_wallet: {
            description: "Minimum amount to withdraw is {min_amount}{currency}"
        },
    },

    withdrawal_form_error: {
        bank_transfer: {
            title: "Warning",
            description: "Add the missing info in your Bank Transfer page",
        },
        bitsafe: {
            title: "Warning",
            description: "Add the missing info in your bitsafe page",
        },
        wire_transfer: {
            title: "Warning",
            description: "Add the missing info in your Crypto page",
        },
        crypto_wallet: {
            title: "Warning",
            description: "Add the missing info in your Crypto page",
        },
    },
    no_content_warning: "Upload at least one content in your story",
    content_expiring: "Your stories expiration time is less than Promotion time",
    filter_select: "Please select at least one section",
    subscription_cancelled: "Subscription cancelled successfully",
    renewal_message: "{user_name} changed {gender} subscription from {old_price} to {new_price}, would you like to renew?",
    subscription: "Subscription",
    max_price_error: "The price must be between {min_dm_price} and {max_dm_price}",
    transaction: 'Transaction',
    invalid_tip: 'Tip must be more than 0',
    referral_code_saved: 'Referral code saved successfully',
    boost: {
        saved: 'Boost created successfully',
    },
    post_upload: {
        success: 'Yay! Success',
        failed: 'Oops! Try again',
    },
    ok: 'Okay',
    tap_back:'Tap Back',
    tap_next:'Tap Next',
    pause:'Pause',
    business_days: 'Business Days',
    fee: 'Fee',
    payment_method: {
        not_saved: "Data is not saved, something went wrong",
        saved: "Saved",
    },
};

export default {
    success: '¡Hecho!',
    password_changed: 'Tu contraseña ha sido cambiada',
    something_went_wrong: 'Oops... Algo salió mal',
    message_seen: 'Seen',
    pin: 'Pin',
    type_story: 'Story',
    type_audio: 'Audio',
    slide_to_cancel: 'Slide to cancel',
    type: {
        audio: "Audio",
        story: "Photo",
        tip: "Tip",
        creator_tip: '{user} requested {item}',
    },
}

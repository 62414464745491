<template>
    <v-btn
        elevation="2"
        x-large
        :class="{'gradient-button': true, 'max-height': hasMaxHeight}"
        :disabled="disable"
        v-bind="$attrs"
        @click="$emit('click')"
        :loading="loading"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
// В случае необходимости прокинуть props в css
export default {
    name: "GradientButton",
    props: {
        disable: {
            type: Boolean,
            default: false,
        },
        hasMaxHeight: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
    }



}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.gradient-button {
    cursor: pointer;
    margin-bottom: 30px;
    background: $accent-background;
    margin-left: 30px;
    margin-right: 30px;
}
.max-height{
    max-height: 50px;
}
</style>

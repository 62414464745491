<template>
    <i :class="`icon icon-${icon} app-icon-white ${clickable === true ? 'app-icon-clickable' : ''} ${active === true?'app-icon-active':''}`"
       @click="$emit('click', {name: icon})" v-bind="$attrs" v-on="$listeners" :style=" `--color: ${color}; --size: ${size}px;`">

    </i>
</template>

<script>

export default {
    name: "AppIcon",
    components: {},
    props: {
        icon: {
            type: String,
            default: 'home'
        },
        active: {
            type: [String, Boolean],
            default: false
        },
        size: {
            type: [String, Number],
            default: '25'
        },
        color: {
            type: String,
            default: 'white'
        },
        clickable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currentIcon() {
            return `${this.icon}Icon`;
        },
        isActive() {
            return this.active === 'true' || this.active === true
        }
    }
}
</script>

<style lang="scss">
.icon{
    color: white
}

.app-icon {
    &-white {
        fill: white;
        color: var(--color) !important;
        font-size: var(--size);
    }

    &-active,
    &-clickable:hover {
        fill: #ff7700;
        color: #ff7700 !important;
    }

    &-clickable {
        cursor: pointer;
    }
}
</style>

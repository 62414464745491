export default async function guest ({ next  }){
    const token = await localStorage.getItem('auth_token_default')
    const user = await localStorage.getItem('user')
    if(token && user){
        return next({
            //name: 'user.own.profile.view',
            patch: '/profile'
        })
    }
    return next()
}

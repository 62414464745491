export default function auth({next, router}) {
    const token = localStorage.getItem('auth_token_default')
    if (token) {
        return next()
    }
    if (router.currentRoute.name === 'login') {
        return router.push({name: 'home'})
    }
    return router.push({name: 'login'})
}

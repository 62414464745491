<template>
    <v-slide-y-reverse-transition :hide-on-leave="true">
        <slot></slot>
    </v-slide-y-reverse-transition>
</template>
<script>
export default {
    name: 'app-transition',
}
</script>

<template>
    <Dialog :closeable="false" v-if="isBlock">
        <div v-if="isShowMobileInstruction">
            <div class="text-h5 mb-4">
                {{$t('dialogs.block_desktop.title')}}
            </div>
            <div class="mb-4">
               <span class="font-weight-bold">{{$t('dialogs.block_desktop.iphone.title')}}</span>
                <ul>
                    <li v-for="(v, i) in $t('dialogs.block_desktop.iphone.list')" :key="i">
                        {{v}}
                    </li>
                </ul>
            </div>
            <div>
                <span class="font-weight-bold">{{$t('dialogs.block_desktop.android.title')}}</span>
                <ul>
                    <li v-for="(v, i) in $t('dialogs.block_desktop.android.list')" :key="i">
                        {{v}}
                    </li>
                </ul>
            </div>
        </div>
        <div v-else
             class="text-h6"
        >{{ $t('dialogs.block_desktop.only_mobile') }}</div>
    </Dialog>
</template>

<script>

import breaks from "@/mixins/breaks";
import Dialog from "@/components/app/form/Dialog";

export default {
    name: "BlockDesktop",
    mixins: [breaks],
    components: {Dialog},
    computed: {
        isBlock() {
            if (this.isExceptRoute()) {
                return false;
            }
            return !this.isMobile
        },
        isShowMobileInstruction() {
            const {name} = this.$route
            return [
                name === 'login',
                name === 'admin-login',
                name === 'sign-up',
            ].some(v => v)
        }
    },
    methods: {
        isExceptRoute() {
            return [
                this.$route.fullPath === '/',
                this.$route.meta.layout === 'admin-dashboard',
                this.$route.name === 'contact',
                this.$route.name === 'terms',
                this.$route.name === 'faq',
                this.$route.name === 'admin-login',
                this.$route.name === 'cookie',
                this.$route.name === 'standard-agreement-noodzly-noodzler',
                this.$route.name === 'dmca-takedown-policy',
                this.$route.name === 'anti-slavery-trafficking',
                this.$route.name === 'anti_fraud_policy',
                this.$route.name === 'privacy-policy',
            ].some(v => v)
        }
    }
}
</script>

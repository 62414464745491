import languages from "./es/languages";
import pages from "./es/pages";
import messages from "./es/messages";
import dialogs from "./es/dialogs";
import components from "./es/components";

export  default {
    languages,
    pages,
    messages,
    dialogs,
    components,
}

<template>
    <app-transition>
        <Dialog
            class="SubscriptionPrice"
            :only-text="true"
            :closeable="false"
        >
            <v-row text-center="">
                <v-col>
                    <div class="SubscriptionPrice-title">
                        {{ $t('dialogs.renewal_message', { user_name: textData.creator_name, gender: genderText, old_price: oldPrice, new_price: newPrice }) }}
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn outlined block class="text-capitalize" @click="renewSubscription">
                        {{ $t('dialogs.yes') }}
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn outlined block class="text-capitalize" @click="handleClose">
                        {{ $t('dialogs.no') }}
                    </v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";
import { CURRENCY_SYMBOL } from "@/configs/constants";
export default {
    name: "SubscriptionPriceChangedDialog",
    data: () => ({
        currency: CURRENCY_SYMBOL,
    }),
    methods: {
        handleClose() {
            this.$emit('close', this.textData.id);
        },
        renewSubscription() {
            this.$emit('renew', this.textData.creator_id);
        }
    },
    computed: {
        genderText() {
            return this.textData.gender == 'male' ? 'his' : 'her';
        },
        oldPrice() {
            return this.currency + this.textData.old_price
        },
        newPrice() {
            return this.currency + this.textData.new_price
        }
    },
    props: {
        textData: {
            type: Object,
            default: {},
        }
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">

</style>

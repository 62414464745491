import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import middlewarePipeline from "./middleware/middlewarePipeline";
import publicRoutes from './public.routes'
import adminRoutes from './admin.routes'
import dashboardRoutes from './dashboard.routes'
import appRoutes from './app.routes'
import userRoutes from './user.routes'
import otherUser from "@/router/otherUser";
import {setReferralCodeByRoute} from "@/helpers/referral";

Vue.use(VueRouter)

const routes = [
    ...publicRoutes,
    ...adminRoutes,
    ...dashboardRoutes,
    ...appRoutes,
    ...userRoutes,
    ...otherUser
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})


router.beforeEach((to, from, next) => {
    setReferralCodeByRoute(to)
    window.scrollTo(0, 0)
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        router,
        store,
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

router.afterEach(() => {
    //
})


export default router

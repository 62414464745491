export default {
    success: 'Готово!',
    password_changed: 'Ваш пароль был изменен',
    something_went_wrong: "Упс.. Что-то пошло не так",
    message_seen: 'Seen',
    pin: 'Pin',
    type_story: 'Story',
    type_audio: 'Audio',
    slide_to_cancel: 'Slide to cancel',
    type: {
        audio: "Audio",
        story: "Photo",
        tip: "Tip",
        creator_tip: '{user} requested {item}',
    },
}

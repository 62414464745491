<template>
    <v-btn large
           :type="type"
           max-width="100%"
           width="340"
           :to="to"
           @click="onClick"
           :loading="loading"
           block>
        {{ text }}
    </v-btn>
</template>

<script>
export default {
  name: "ButtonLarge",
  props: {
    text: {
      type: String,
      required: true
    },
    to: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click');
    }
  }
}
</script>

import {setCookie, getCookie} from "@/helpers/functions";

const REFERRAL_KEY = 'referral'
export const getReferralCode= () => {
    const code = getCookie(REFERRAL_KEY)
    return  code ?  code : null
}

export const setReferralCode = (code) => {
    setCookie(REFERRAL_KEY, code, 24 * 60 * 60 * 1000)
}


export const setReferralCodeByRoute = (route) => {
    const referralCode = route.query.referral_code
    if (referralCode) {
        setReferralCode(referralCode)
    }
}

import email_verified from "@/router/middleware/email_verified"
import auth from "../middleware/auth";

export default [
    {
        path: 'splash-screen',
        name: 'splash-screen',
        component: () => import(/* webpackChunkName: "app-splash-screen" */ '@/views/app/SplashScreen'),
        meta: {
            auth: false,
            className: 'splash',
            layout: 'default',
        }
    },
    {
        path: 'home',
        name: 'auth.home',
        component: () => import(/* webpackChunkName: "app-home" */ '@/views/app/Home'),
        meta: {
            auth: true,
            middleware: [auth, email_verified],
            bottomBar: true
        }
    }
];


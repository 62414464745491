const state = {
    snackbar: {},
    activeBottomBar: true,
    loader: false,
};

const getters = {

};


const mutations = {
    hideSnackBar: (state) => {
        state.snackbar = {...state.snackbar, text: '', show: false}
    },
    showSnackBar: (state, text) => {
        state.snackbar = {text, show: true}
    },

    hideCameraScreen: (state)=>{
        state.isShowCameraScreen = {...state.isShowCameraScreen, step: 4}
    },
    hideResultScreen: (state)=>{
        state.isShowCameraScreen = {...state.isShowCameraScreen, step: 4}
    },
    hideBottomBar: (state) => {
        state.activeBottomBar = false
    },
    showBottomBar: (state) => {
        state.activeBottomBar = true
    },
    handleLoader: (state, value) => {
        state.loader = value
    },
};

const actions = {

};

export default {
    state, getters, mutations, actions
}

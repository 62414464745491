import auth from "./middleware/auth";
import email_verified from "@/router/middleware/email_verified";

export default [
    {
        path: '/user/:id',
        name: 'otherUser.profile.view',
        component: () => import('../views/app/user/otherUser/OtherUser'),
        meta: { middleware: [auth,email_verified]}
    },
    {
        path: '/:slug',
        name: 'otherUser.profile.viewSlug',
        component: () => import('../views/app/user/otherUser/OtherUser'),
        meta: { middleware: [auth,email_verified]}
    },
    {
        path: '/:slug/streams',
        name: 'otherUser.profile.viewStreams',
        component: () => import('../views/app/user/otherUser/OtherUserStreams'),
        meta: { middleware: [auth,email_verified]}
    },
]

<template>
    <v-app v-if="allowedPath.indexOf($route.name) === -1">
        <v-progress-linear v-if="loader" indeterminate color="#FD5312" />
        <router-view />

        <div v-if="showSplashScreen" class="splash_screen">
            <img :src="require('@/assets/logo_animation.gif')" alt="Loading..." />
        </div>

        <div v-if="displayUpdatedSubscription">
            <subscription-price-changed-dialog
                :text-data="displayUpdatedSubscription"
                @close="closeSubscriptionDialog"
                @renew="renewSubscription"
            />
        </div>

        <insufficient-funds-dialog
            v-if="showInsufficientFundsDialog"
            @close="showInsufficientFundsDialog = false"
        />

        <v-snackbar
            top
            v-model="snackbar.show"
            color="orange darken-2 v-snack__fixed"
        >
            {{ snackbar.text }}
        </v-snackbar>
        <block-desktop />
        <accept-cookies />
    </v-app>

    <v-app v-else>
        <v-progress-linear v-if="loader" indeterminate color="#FD5312" />
        <app-transition>
            <router-view />
        </app-transition>
        <saas-snackbar />
        <block-desktop />
        <accept-cookies />
    </v-app>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SaasSnackbar from "./components/SaasSnackbar";
import { DEFAULT_TITLE } from "./configs/constants";
import notifications from "@/notifications";
import BlockDesktop from "@/components/app/common/BlockDesktop";
import AcceptCookies from "@/components/app/common/AcceptCookies";
import SubscriptionPriceChangedDialog from "./components/app/dialogs/SubscriptionPriceChangedDialog.vue";
import InsufficientFundsDialog from "@/components/app/dialogs/InsufficientFundsDialog";


export default {
    name: "App",
    computed: {
        layout() {
            return `${this.$route.meta.layout || "empty"}-layout`;
        },
        isAppReady() {
            return (
                !Object.values(this.$store.state.loadedData).some(
                    (v) => v === false
                ) && this.$auth.ready()
            );
        },
        ...mapState({
            snackbar: (state) => state.site.snackbar,
            step: (state) => state.story.isShowCameraScreen.step,
            displayUpdatedSubscription: (state) => state.user.user.data.updatedSubscriptionPrice,
            loader: (state) => state.site.loader,
        }),
    },
    watch: {
        step(value) {
            if (typeof value !== "undefined" && value === 2) {
                this.$core.storyCore.stopCamera();
            }
        },
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || DEFAULT_TITLE;

                if (
                    from?.path.includes("/email-verify") ||
                    from?.path.includes("/login")
                ) {
                    this.handleSplashScreenAnimationRun();
                    this.getOwnMainUserData(this.$auth.user());
                }
            },
        },
    },
    components: {
        AcceptCookies,
        BlockDesktop,
        SaasSnackbar,
        SubscriptionPriceChangedDialog,
        InsufficientFundsDialog,
    },
    data: () => ({
        allowedPath: [
            "login",
            "admin-login",
            "sign-up",
            "email-verify",
            "home",
            "forget-password",
            "reset-password",
            "404",
            "403",
            "faq",
            "terms",
            "cookie",
            "contact",
            "anti_fraud_policy",
            "standard-agreement-noodzly-noodzler",
            "anti-slavery-trafficking",
            "dmca-takedown-policy",
            "privacy-policy",
        ],

        // splash screen
        showSplashScreen: false,

        // pull to refresh logo
        startY: null,
        isPulling: false,
        content: "",
        distance: 0,
        showInsufficientFundsDialog: false,
    }),
    methods: {
        ...mapMutations(["setOwnToken", "hideSnackBar", "showSnackBar", "setUserDisplayUpdatedSubscription"]),
        ...mapActions([
            "getAuthUser",
            "getOwnMainUserData",
            "unreadNotifications",
            "unreadMessages",
            "getUserStories",
            "resubscribe",
            "subscriptionCancel"
        ]),
        handleNotifications() {
            this.unreadNotifications();
            this.unreadMessages();
        },

        handleSplashScreenAnimationRun() {
            this.showSplashScreen = true;
            setTimeout(() => {
                this.showSplashScreen = false;
            }, 3000);
        },
        async closeSubscriptionDialog(id) {
            await this.subscriptionCancel(id).then(() => {
                this.showSnackBar(this.$t("dialogs.subscription_cancelled"));
                this.setUserDisplayUpdatedSubscription(null)
            });
        },
        async renewSubscription(id) {
            await this.resubscribe(id)
            .then(() => {
                this.showSnackBar(this.$t("dialogs.subscribed"));
                this.setUserDisplayUpdatedSubscription(null)
            })
            .catch((error) => {
                this.setUserDisplayUpdatedSubscription(null)
                if (error.response.data.err_msg) {
                    this.showInsufficientFundsDialog = true;
                    return;
                }
                this.showSnackBar(error.response.data.err_msg);
            });
        },

        /*
         * pull to refresh
         */
        // touchStart(e) {
        //     if (window.scrollY === 0) {
        //         this.startY = e.touches[0].clientY;
        //         this.isPulling = true;
        //     }
        // },
        // touchMove(e) {
        //     if (this.isPulling) {
        //         const currentY = e.touches[0].clientY;
        //         this.distance = currentY - this.startY;
        //
        //         if (this.distance > 0) {
        //             e.preventDefault();
        //             if (this.distance > 80) {
        //                 document.getElementById('logo_stretched').style.opacity = 1;
        //                 console.log(document.getElementById('app').offsetTop)
        //             }
        //         } else {
        //             this.resetIndicator();
        //         }
        //     }
        // },
        // touchEnd() {
        //     if (this.isPulling) {
        //         if (this.distance > 80) {
        //             location.reload();
        //         }
        //
        //         this.resetIndicator();
        //     }
        // },
        // resetIndicator() {
        //     this.isPulling = false;
        //     document.getElementById('logo_stretched').style.opacity = 0;
        // }
    },
    updated() {
        const bearer = `Bearer ${localStorage.getItem("auth_token_default")}`;
        if (
            this.$echo.options.auth.headers.Authorization !== bearer ||
            this.$echo.options.auth.headers.Authorization === "Bearer null"
        ) {
            this.$echo.options.auth.headers.Authorization = bearer;
            this.$echo.connector.connect();
        }
    },
    // mounted() {
    //     document.addEventListener('touchstart', this.touchStart);
    //     document.addEventListener('touchmove', this.touchMove);
    //     document.addEventListener('touchend', this.touchEnd);
    // },
    created() {
        this.$auth.load().then(() => {
            if (this.$auth.check()) {
                const userId = this.$auth.user().id;
                this.$echo.connector.connect();

                this.getOwnMainUserData(this.$auth.user());
                notifications
                    .setInformer(this.showSnackBar)
                    .setActions(this.handleNotifications)
                    .setUserId(userId)
                    .setEcho(this.$echo)
                    .setContext(this)
                    .run();
                this.$echo
                    .private(`user.${notifications.userId}`)
                    .notification(({ type }) => {
                        if (type === "chat.new-message") {
                            return this.unreadMessages();
                        }
                    });
            }
        });

        const token = localStorage.getItem("auth_token_default");
        if (token !== null) {
            this.setOwnToken(token);
        }
    },
};
</script>
<style lang="scss">
@import "./style.css";

:root {
    --v-primary-base: #ff7700 !important;
    --v-anchor-base: white !important;
    background-color: black;
}

.loading {
    position: fixed;
    inset: 0;
    background: black;
    display: none;
}

.loadingApp {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

#nprogress .bar {
    height: 4px !important;
}

[vs-theme="dark"] {
    background-color: rgba(var(--vs-background), 1);
    color: rgba(var(--vs-text), 1);
}

a {
    text-decoration: none;
}

*[black-text] {
    color: black !important;
}

*[black-text]::placeholder {
    color: rgba(0, 0, 0, 0.3) !important;
}

*[white-text] {
    color: white !important;
}

*[white-text]::placeholder {
    color: rgba(255, 255, 255, 0.3) !important;
}

*[text-center] {
    text-align: center !important;
}

.v-snack {
    &__fixed {
        min-width: initial;
        position: fixed;
        top: 16px;
    }
}

.splash_screen {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: black;
    animation: splashScreenAnimation 3s ease-in-out;

    img {
        width: 200px;

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        animation: logoAnimation 3s ease-in-out;
    }
}

.splash_screen--default {
    video {
        animation: logoAnimation 3s ease-in-out;
    }
}

.splash_screen--home {
    video {
        animation: logoAnimationHome 3s ease-in-out;
    }
}

@-webkit-keyframes splashScreenAnimation {
    0% {
        background: black;
    }
    80% {
        background: black;
    }
    100% {
        background: transparent;
    }
}

@keyframes splashScreenAnimation {
    0% {
        background: black;
    }
    80% {
        background: black;
    }
    100% {
        background: transparent;
    }
}

@-webkit-keyframes logoAnimation {
    0% {
        opacity: 1;
        width: 100%;
    }
    50% {
        width: 100%;
    }
    70% {
        top: 50%;
        width: 200px;
    }
    80% {
        top: 90px;
        opacity: 1;
    }
    100% {
        top: 90px;
        opacity: 0;
    }
}
@keyframes logoAnimation {
    0% {
        opacity: 1;
        width: 100%;
    }
    50% {
        width: 100%;
    }
    70% {
        top: 50%;
        width: 200px;
    }
    80% {
        top: 90px;
        opacity: 1;
    }
    100% {
        top: 90px;
        opacity: 0;
    }
}

//#logo_stretched {
//    position: fixed;
//    z-index: 999;
//    left: 50%;
//    top: 12px;
//    transform: translate(-50%);
//    transition: .3s;
//    opacity: 0;
//
//    video {
//        width: 50px;
//    }
//}
</style>

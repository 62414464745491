export default {
    delete: "Elimina",
    close: "Chiudi",
    preview_document: "Anteprima Documento",
    tip_menu: {
        send_button: "Invia",
        type_send: "Invia",
        type_request: "Richiesta",
        your_message: "Il Tuo Messaggio",
        your_want_to_buy: "Il Tuo Messaggio",
    }
}

<template>
    <Dialog scrollable v-if="isShow" :closeable="false" class="accept-dialog">
        <div class="pa-4">
            <div v-if="!showPolicy">
                <div class="row justify-center">
                    <img
                        src="@/assets/images/logo/noodzly-logo.png"
                        alt="noodzly logo"
                        style="width: 150px"
                    />
                </div>

                <div class="text-center text-h5 mt-8 mb-1">
                    Are you at least 18 years old?
                </div>
                <div
                    class="text-center text-h6 font-weight-medium"
                    style="opacity: 0.75"
                >
                    Please verify your age
                </div>

                <div class="text-center mt-8">
                    <app-icon icon="check" size="12" class="mr-1" />
                    By proceeding you accept
                    <span
                        style="text-decoration: underline"
                        class="cursor-pointer"
                        @click="showPolicy = true"
                    >
                        our policy
                    </span>
                </div>

                <div class="mt-4 flex flex-column">
                    <v-btn
                        class="w-full font-weight-black accept"
                        @click="onAccept"
                    >
                        Yes, I am 18 or older
                    </v-btn>

                    <v-btn
                        class="w-full font-weight-black mt-4"
                        @click="onDecline"
                    >
                        No, I am under 18
                    </v-btn>
                </div>
            </div>

            <div v-else>
                <div class="text-center mb-8">
                    <h2 class="text-h5 mb-4">
                        {{ $t("pages.privacy.title") }}
                    </h2>
                    <p class="text-body-1 mb-4">
                        {{ $t("pages.privacy.desc_1") }}
                    </p>
                    <p class="text-body-1">{{ $t("pages.privacy.desc_2") }}</p>
                </div>

                <v-btn @click="showPolicy = false" class="w-full">
                    {{ $t("dialogs.back") }}
                </v-btn>
            </div>
        </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";
import { hasCookie } from "@/helpers/functions";
import { setCookie } from "@/helpers/functions";

const ACCEPT_COOKIE_NAME = "qtyph3ndgb5j";

export default {
    name: "AcceptCookies",
    components: { Dialog },
    data() {
        return {
            isAccept: false,
            showPolicy: false,
        };
    },
    computed: {
        isShow() {
            return (
                !(this.$route.name === "cookie") &&
                !(this.isAccept || hasCookie(ACCEPT_COOKIE_NAME))
            );
        },
    },
    methods: {
        onAccept() {
            setCookie(ACCEPT_COOKIE_NAME, 1, 24 * 365);
            this.isAccept = true;
        },
        onDecline() {
            window.location = "https://google.com";
        },
    },
};
</script>

<style lang="scss">
@import "@/sass/modules/_variables";

.accept-dialog {
    .DialogContent {
        width: 100% !important;
        max-width: 450px !important;
        background: black !important;
        border: 1px solid #2e2e2e;
        box-shadow: 0 0 40px 5px rgba(255, 255, 255, 0.15);
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 24px !important;

        button:first-child {
            background: $accent-background !important;
            //background: #ff9900 !important;
            //color: black !important;
        }

        button:last-child {
            background: #202020 !important;
            color: white !important;
        }
    }
}
</style>

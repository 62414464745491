import {requestCore} from '@/core/index'

const rQ = requestCore;
const state = {
    token: null,
    user: {},
};

const getters = {};

const mutations = {
    setToken(state, token) {
        state.token = token
        window.axios.defaults.headers.common.Authorization = `Bearer ${token}`
    },
    setUser: async (state, user) => {
        return new Promise(() => {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        })
    },
    clearUserData: async () => {
        return new Promise(resolve => {
            localStorage.removeItem('auth_token_default')
            localStorage.removeItem('user')
            localStorage.removeItem('home_filters')
            resolve('ok')
        })
    },

};

const actions = {
    async login({commit}, params) {
        let res = await rQ.setData('login', params,['auth', 'Login'], '', commit, '', false)
        let token = await res.token
        commit('setOwnToken', token)
        return res;
    },

    getAuthUser({commit}) {
        return new Promise((resolve, reject) => {
            window.axios.get('/api/users/auth').then(async res => {
                await commit('setUser', res.data)
                resolve(res.data)
            }).catch(e => {
                reject(e)
            })
        })
    },
    setUser: async ({commit}, params) => {
        await commit('setToken', params.token)
        await commit('setUser', params.user)
        return params.user
    },
    signUp: async ({commit}, params) => {
        let res = await rQ.setData('sign-up', params,['auth', 'SignUp'], '', commit, '', false)
        let token = await res.token
        commit('setOwnToken', token)
        return res;
    },
    logout: async ({commit},) => {
        return await commit('clearUserData')
    },
};

export default {
    state, getters, mutations, actions
}

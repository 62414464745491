import guest from "./middleware/guest"
import auth from "./middleware/auth"

export  default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "app-splash-screen" */ '@/views/app/SplashScreen'),
        meta: {
            //auth: false,
            className: 'splash'
        }
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {middleware: [guest] }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: 'app-login' */ '@/views/app/auth/Login'),
        meta: {
            title: 'Login Noodzly',
            middleware: [guest],
        }
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: 'app-sign-up' */ '@/views/app/auth/SignUp'),
        meta: {
            title: 'Sign up Noodzly',
            middleware: [guest],
        }
    },
    {
        path: '/forget-password',
        name: 'forget-password',
        component: () => import('@/views/app/auth/ForgetPassword'),
        meta: { middleware: [guest]}
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/app/auth/ResetPassword'),
        meta: { middleware: [guest] }
    },
    {
        path: '/403',
        name: '403',
        component: () => import('../views/static/403.vue'),
        meta: { }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        component: () => import('../views/static/PrivacyPolicy.vue'),
        meta: { }
    },
    {
        path: '/stream-rules',
        name: 'stream-rules',
        component: () => import('../views/static/StreamRules.vue'),
        meta: { }
    },
    {
        path: '/anti-fraud-policy',
        name: 'anti_fraud_policy',
        component: () => import('../views/static/AntiFraudPolicy'),
        meta: { }
    },
    {
        path: '/anti-slavery-and-anti-trafficking-statement',
        name: 'anti-slavery-trafficking',
        component: () => import('../views/static/AntiSlaveryAndTrafficking'),
        meta: { }
    },
    {
        path: '/dmca-takedown-policy',
        name: 'dmca-takedown-policy',
        component: () => import('../views/static/DmcaTakedownPolicy'),
        meta: { }
    },
    {
        path: '/standard-agreement-between-noodzly-and-noodzler',
        name: 'standard-agreement-noodzly-noodzler',
        component: () => import('../views/static/StandardAgreement'),
        meta: { }
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/errors/404'),
        meta: {middleware: [guest]}
    },
    {
        path: '/email-verify',
        name: 'email-verify',
        component: () => import('@/views/app/user/EmailVerify'),
        meta: {middleware: [auth]}
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/views/static/FAQ.vue'),
    },
    {
        path: '/terms',
        name: 'terms',
        component: () => import('@/views/static/Terms'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/static/Contact'),
        meta: {
            title: 'Contact Noodzly',
            middleware: [guest],
        }
    },
    {
        path: '/cookie',
        name: 'cookie',
        component: () => import('@/views/static/Cookie'),
    },
]

<template>
    <app-transition>
        <Dialog @close="handleClose">
            <v-row class="fill-width" text-center="">
                <v-col>
                    <p style="font-size: 18px">{{ $t('dialogs.balance_insufficient') }}</p>

                    <v-row class="mt-4 d-flex wrap px-5">
                        <v-col cols="12" @click.stop="handleNav">
                            <span class="top_up_btn yes pt-3 pb-3 pl-7 pr-7 d-block text-center">
                                {{$t('dialogs.top_up')}}
                            </span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";
import GradientButton from "@/components/app/button/GradientButton.vue";

export default {
    name: "InsufficientFundsDialog",
    components: {
        GradientButton,
        Dialog,
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleNav() {
            this.$router.push({name: 'user.own.profile.wallet.topUp'});
            this.$emit('close');
        }
    }
}
</script>


<style scoped lang="scss">
.top_up_btn{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.top_up_btn:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
</style>

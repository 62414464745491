import requestCore from "@/core/requestCore"
const state = {
    anotherModels: [],
}

const mutations = {
    setAnotherModelsData: (state, data)=>{
        state.anotherModels = data;
    }
}

const actions = {
    async getAnotherModels({commit}) {
        return await requestCore.getData(`another-models`, ['anotherModels'], 'setAnotherModelsData', commit, ``, {}, false, true)
    },
    async addAnotherModel({commit}) {
        return await requestCore.setData(`another-models`, {}, ['anotherModels'], '', commit, '', false, true)
    },
}

export default {
    state, mutations, actions
}

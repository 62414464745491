import ButtonLarge from "@/components/app/button/ButtonLarge"
import AppIcon from "@/components/app/common/AppIcon"
import AppTransition from "@/components/app/common/AppTransition"
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const GlobalComponents = {
  install(Vue) {
    Vue.component('btn-large', ButtonLarge)
    Vue.component('app-icon', AppIcon)
    Vue.component('app-transition', AppTransition)
    Vue.component('v-provider', ValidationProvider)
    Vue.component('v-observer', ValidationObserver)
  }
}

export default GlobalComponents

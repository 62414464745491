export default {
    delete: "Delete",
    close: "Close",
    preview_document: "Preview Document",
    tip_menu: {
        send_button: "Send",
        type_send: "Send",
        type_request: "Request",
        your_message: "Your Message",
        your_want_to_buy: "Your Message",
    }
}

import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth";
import site from "./site";
import story from "@/store/story";
import user from "@/store/user";
import otherUser from "@/store/otherUser";
import anotherModels from "@/store/anotherModels";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        buttonsLoading: {
            infoBtn: false
        },
        loadedData: {},
    },
    mutations: {
        setMainLoadedData(state, field){
            let obj = {}
            obj[field] = true;
            state.loadedData = Object.assign({}, state.loadedData, obj)
                //[field] = true
        },
        setMainUnloadedData(state, field){
            let obj = {}
            obj[field] = false;
            state.loadedData = Object.assign({}, state.loadedData, obj)
        },
        setBtnLoadingEnd(state, btn){
            Vue.set(state.buttonsLoading, btn, false)
        },
        setBtnLoadingStart(state, btn){
            Vue.set(state.buttonsLoading, btn, true)
        },
    },
    actions: {
    },
    getters: {
        loadingBtn: (state) => (id) => {
            if(typeof  id === 'object')
                id = id.join('/')
                    //console.log(id, state.buttonsLoading[id])
            return state.buttonsLoading[id];
        },
    },
    modules: {
        auth, site, story, user, otherUser, anotherModels
    }
})

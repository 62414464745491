<template>
    <div
        class="overlay"
        :class="{
            'scrollable': scrollable
        }"
    >
        <v-container fluid class="h-full">

            <v-row align="center" justify="center" class="h-full">
                <v-col>
                    <div
                        class="DialogContent pa-6"
                        :class="{
                            'only-text': onlyText
                        }"
                    >
                        <div class="DialogContent-header">
                            <app-icon
                                size="15"
                                icon="close"
                                v-if="closeable"
                                :clickable="true"
                                @click="handleClickClose"
                            ></app-icon>
                        </div>
                        <div class="DialogContent-body">
                            <slot/>
                        </div>
                    </div>
                </v-col>
            </v-row>

        </v-container>

    </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
    name: "Dialog",
    props: {
        scrollable: {
            type: Boolean,
            default: false,
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        onlyText: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        ...mapMutations([
            'setStopStory'
        ]),
        handleClickClose() {
            this.setStopStory(false)
            this.$emit('close')
        }
    },
    mounted() {
        this.setStopStory(true)
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
.yes{
    text-align: end;
}
.choose{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
}
.choose:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
.link {
    text-decoration: none;
    color: white;
    font-size: 1.3em;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.scrollable {
        position: absolute;
    }
}

.DialogContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: $accent-background;
    min-height: 140px;
    border-radius: 14px;
    max-width: 1024px;
    margin: 0 auto;
    padding: 24px;

    a {
        margin-left: 6px;
        margin-right: 6px;
    }

    &-header {
        position: relative;
        width: 100%;

        i {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    &-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        width: 100%;
        p {
            margin-bottom: 0px;
        }
    }

    &.only-text {
        min-height: inherit;
        padding: 16px !important;

        .DialogContent-body {
            width: 100%;
        }

        .DialogContent-header i {
            top: -5px;
            right: -5px;
        }
    }


}
.title{
    font-size: 1.3em;
}
</style>

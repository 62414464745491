import AuthRoutes from '@/router/app.routes/auth.routes';
import MainRoutes from '@/router/app.routes/main.routes';

export default [
  {
    path: '/app',
    redirect: '/app/splash-screen',
    component: () => import('@/layouts/app/Default'),
    children: [
      ...MainRoutes,
      ...AuthRoutes,
    ]
  }
];

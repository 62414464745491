import auth from "./middleware/auth";
import email_verified from "@/router/middleware/email_verified";

export default [
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/app/user/Search'),
        meta: { middleware: [auth,email_verified]}
    },
    {
        path: '/notification',
        name: 'notification',
        component: () => import('@/views/app/user/Notification'),
        meta: { middleware: [auth,email_verified]}
    },

]

import axios from 'axios';
import store from '@/store'

axios.defaults.withCredentials = true;
axios.defaults.headers.Accept = "application/json";
axios.defaults.baseURL = '/' // process.env.VUE_APP_APP_URL;
window.axios = axios;

axios.interceptors.request.use(config => {
    if(!config.hideLoader) store.commit('handleLoader', true);
    return config;
});
  
axios.interceptors.response.use(
    response => store.commit('handleLoader', false) || response,
    error => store.commit('handleLoader', false) || Promise.reject(error)
);

export default axios

export default async function isAdmin ({ next, store }){
    store.dispatch('getCurrentUserData').then((res) => {
        let primaryRoleName = res.data.primary_role_name

        if (primaryRoleName !== 'admin') {
            return next({
                name: '403'
            })
        }

        return next()
    })
}

export default {
    delete: "Удалить",
    close: "Закрыть",
    preview_document: "Просмотреть документ",
    tip_menu: {
        send_button: "Отправить",
        type_send: "Отправить",
        type_request: "Запрос",
        your_message: "Ваше сообщение",
        your_want_to_buy: "Ваш сообщение",
    }
}

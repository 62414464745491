export default {
  // global theme for the app
  // globalTheme: 'light', // light | dark

  // // side menu theme, use global theme or custom
  // menuTheme: 'dark', // global | light | dark
  //
  // // toolbar theme, use global theme or custom
  // toolbarTheme: 'global', // global | light | dark
  //
  // // show toolbar detached from top
  // isToolbarDetached: false,
  //
  // // wrap pages content with a max-width
  // isContentBoxed: false,
  //
  // // application is right to left
  // isRTL: false,

  // dark theme colors
  dark: {
    background: '#05090c',
    // background: '#0a1b27',
    surface: '#111b27',
    primary: '#0b4f67',
    secondary: '#829099',
    accent: '#82b1ff',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  },

  // light theme colors
  light: {
    background: '#ffffff',
    surface: '#eae9e0',
    primary: '#6fa8ff',
    secondary: '#03dac5',
    accent: '#048ba8',
    error: '#ef476f',
    info: '#2186db',
    success: '#05c391',
    warning: '#ffd166',
    deep: '#326386',
  }
}

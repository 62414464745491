export default [
    {
        path: 'login',
        name: 'app-login',
        component: () => import('@/views/app/auth/Login'),
        meta: {
            auth: false,
            layout: 'default',
        }
    },
    {
        path: 'sign-up',
        name: 'app-sign-up',
        component: () => import('@/views/app/auth/SignUp'),
        meta: {
            auth: false,
            layout: 'default',
        }
    },
    {
        path: 'forget-password',
        name: 'app-forget-password',
        component: () => import('@/views/app/auth/ForgetPassword'),
        meta: {
            auth: false,
            layout: 'default',
        }
    },
    {
        path: 'reset-password',
        name: 'app-reset-password',
        component: () => import('@/views/app/auth/ResetPassword'),
        meta: {
            auth: false,
            layout: 'default',
        }
    }
];

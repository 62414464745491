
let notifications = {
    $echo: null,
    userId: null,
    informer: null,
    actions: null,
    context: null,
    setInformer(informer) {
        notifications.informer = informer
        return notifications
    },
    setContext(context) {
        this.context = context
        return notifications
    },
    setActions(actions) {
        notifications.actions = actions
        return notifications
    },
    setUserId: (userId) => {
        notifications.userId = userId
        return notifications
    },
    setEcho: ($echo) => {
        notifications.$echo = $echo
        return notifications
    },
    channel: (channel) => {
        channel
            .notification((data)=>{
                if (typeof data.message === 'undefined') {
                    return;
                }
                notifications.informer(data.message)
                notifications.actions()
            })
            .error((err) => {
                console.error(err)
            })
    },
    subscribe: () => {
        const channel = notifications.$echo.private(`subscribe-to-creator.${notifications.userId}`)
        notifications.channel(channel)
    },
    unsubscribe: () => {
        const channel = notifications.$echo.private(`unsubscribe-from-creator.${notifications.userId}`)
        notifications.channel(channel)
    },
    follow: () => {
        const channel = notifications.$echo.private(`follow-to-user.${notifications.userId}`)
        notifications.channel(channel)
    },
    tagged: () => {
        const channel = notifications.$echo.private(`tagged-user.${notifications.userId}`)
        notifications.channel(channel)
    },
    run: () => {
        notifications.subscribe()
        notifications.unsubscribe()
        notifications.follow()
        notifications.tagged()
        notifications.actions()
    }
}

export default notifications
